html {
  overflow-x: hidden;
}
body {
  background-color: black;
  color: white;
  overflow-x: hidden;
  width: 100vw;
  padding-right: 0;
  margin-right: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.page-section {
  margin-top: 10%;
}

#wallet__connect {
  margin-top: 10px;
  padding-bottom: 5px;
}
#brand-name {
  width: fit-content;
  margin: 10px auto;
  font-size: 60px;
  font-weight: 800;
  color: white;
}
.walletConnect {
  left: 84%;
  top: 1%;
  position: absolute;
}
.connect_btn {
  display: block;
  width: 120px;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
  height: fit-content;
  margin: 20px auto;
  font-family: "Poppins", sans-serif;
  color: white;
  background-color: rgb(12, 37, 59);
  border: 1px solid rgb(12, 37, 59);
  font-weight: 500;
}
.connect_btn:hover {
  background-color: rgb(15, 53, 87);
}
@media screen and (max-width: 934px) {
  .walletConnect {
    position: relative;
    display: block;
    margin: 0 auto;
    left: auto;
  }
}
#banner_div {
  margin-bottom: 40%;
}
.banner-slide-container {
  background-color: #a667e6;
}
.banner-image {
  width: 90%;
  margin-left: 3%;
  border: 12px solid white;
  padding: 0;
  position: absolute;
  border-radius: 10px;
  visibility: hidden;
  animation: show 5s infinite;
}
@media screen and (max-width: 992px) {
  .banner-image {
    width: 60%;
    margin-left: 20%;
  }
}
@media screen and (max-width: 767px) {
  .banner-image {
    width: 70%;
    margin-left: 12%;
  }
  #banner_div {
    margin-bottom: 80%;
  }
}
#banner {
  width: 100%;
}

.row {
  flex-wrap: wrap;
  justify-content: center;
}
.col {
  display: flex;
  justify-content: center;
}

@keyframes show {
  0% {
    visibility: visible;
  }
  10% {
    visibility: hidden;
  }
}
.banner-image:nth-child(1) {
  animation-delay: 0s;
}
.banner-image:nth-child(2) {
  animation-delay: 0.5s;
}
.banner-image:nth-child(3) {
  animation-delay: 1s;
}
.banner-image:nth-child(4) {
  animation-delay: 1.5s;
}
.banner-image:nth-child(5) {
  animation-delay: 2s;
}
.banner-image:nth-child(6) {
  animation-delay: 2.5s;
}
.banner-image:nth-child(7) {
  animation-delay: 3s;
}
.banner-image:nth-child(8) {
  animation-delay: 3.5s;
}
.banner-image:nth-child(9) {
  animation-delay: 4s;
}
.banner-image:nth-child(10) {
  animation-delay: 4.5s;
}
.title-section {
  margin-top: 40px;
  text-align: left;
  text-justify: left;
  padding: auto;
  display: flex;
  justify-content: center;
}
#title-text {
  margin-top: auto;
  margin-bottom: auto;
  width: 80%;
  text-align: center;
}
#title-text h1 {
  font-weight: 800;
}
#title-text p {
  font-size: 18px;
  font-weight: 700;
}

.mint_btn {
  width: 80px;
  border-radius: 25px;
  background-color: #a667e6;
  font-family: "Poppins", sans-serif;

  margin-top: 50px;
  font-size: 18px;
}
.mint_btn:hover {
  background-color: white;
  color: #a667e6;
  text-shadow: none;
}
#roadmap {
  margin: 80px auto;
}

.section_heading {
  width: fit-content;
  margin: 2px auto;
  display: block;
  font-size: 45px;
  font-weight: 800;
}
#faq_section {
  text-align: left;
  margin: 20px auto;
}

#faq_section summary {
  font-size: 23px;
  font-weight: 500;
  padding-left: 10px;
  margin: 10px 0;
}
#faq_section p {
  font-size: 18px;
  font-weight: 400;
  padding-left: 30px;
  color: #a667e6;
}
summary:focus-within {
  border: 2px solid rgb(3, 191, 253);
}
#faq_section details:hover summary::-webkit-details-marker:hover {
  color: red;
  background: white;
}
#gallery-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}
.img_card {
  width: 270px;
  border: 5px solid white;
  margin: 20px;
}

footer {
  padding: 15px 0px 12px 0px;
  z-index: 2;
  position: relative;
}

.footer-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.social {
  margin: 0;
  padding: 0;
}

.social li {
  display: inline-block;
  margin-left: 10px;
  text-align: center;
}

.social li a {
  padding: 10px;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background: #26436b;
  display: inline-block;
}

.social li img {
  height: 20px;
  width: 20px;
  margin: auto;
}

footer p {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
  letter-spacing: 1px;
}
@media screen and (max-width: 992px) {
  #brand-name {
    font-size: 35px;
  }
  body {
    font-size: 25px;
  }
  .page-section {
    margin-top: 15%;
  }
  .title-section {
    text-align: center;
  }
  #title-text p {
    font-size: 18px;
  }
  .page-section details {
    font-size: 18px;
  }
  .section_heading {
    font-size: 32px;
  }

  #wallet__connect {
    margin-top: 0;
  }
  .timeline ul {
    padding: 0 0 0 50px;
    border-left: 4px solid #1d3557;
    position: relative;
  }
  footer {
    padding: 20px 0px;
  }
  .footer-wrapper {
    display: block;
    text-align: center;
  }
  .social {
    margin-top: 10px;
  }
  .social li:first {
    margin-left: 0px;
  }
}
