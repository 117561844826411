#background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
}

.emoji1 {
  font-size: 22px;
  position: absolute;
  animation: fall_1 2.5s linear infinite;
}

@keyframes fall_1 {
  0% {
    left: 0;
    top: 0;
  }
  100% {
    left: 1550px;
    top: 600px;
  }
}

@keyframes fall_2 {
  0% {
    left: 230px;
    top: 0;
  }
  100% {
    left: 1550px;
    top: 300px;
  }
}
.emoji2 {
  font-size: 16px;
  position: absolute;
  animation: fall_2 4s linear infinite;
}

.emoji3 {
  font-size: 18px;
  position: absolute;
  animation: fall_3 2s linear infinite;
}
@keyframes fall_3 {
  0% {
    left: 200px;
    top: 50px;
  }
  100% {
    left: 1100px;
    top: 880px;
  }
}
.emoji4 {
  font-size: 22px;
  left: 400px;
  position: absolute;
  top: 200px;
  animation: fall_4 2.7s linear infinite;
}
.emoji5 {
  font-size: 20px;
  position: absolute;
  z-index: 200;
  animation: fall_5 2.1s linear infinite;
}
.emoji6 {
  font-size: 25px;
  position: absolute;
  z-index: 1200;
  animation: fall_6 4.2s linear infinite;
}
.emoji7 {
  font-size: 30px;
  position: absolute;
  z-index: 200;
  animation: fall_7 1.6s linear infinite;
}
.emoji8 {
  font-size: 17px;
  position: absolute;
  z-index: 200;
  animation: fall_8 3.6s linear infinite;
}
.emoji9 {
  font-size: 25px;
  position: absolute;
  z-index: 200;
  animation: fall_9 1.6s linear infinite;
  animation-delay: 2s;
}
.emoji10 {
  font-size: 17px;
  position: absolute;
  z-index: 200;
  animation: fall_10 2.6s linear infinite;
}
.emoji11 {
  font-size: 26px;
  position: absolute;
  z-index: 200;
  animation: fall_11 2.6s linear infinite;
}
.emoji12 {
  font-size: 28px;
  z-index: 200;
  position: absolute;
  animation: fall_12 4.6s linear infinite;
}
.emoji13 {
  font-size: 18px;
  z-index: 200;
  position: absolute;
  animation: fall_13 4s linear infinite;
}

@keyframes fall_4 {
  0% {
  }
  100% {
    left: 1100px;
    top: 1300px;
  }
}
@keyframes fall_5 {
  0% {
  }
  100% {
    left: 1300px;
    top: 800px;
  }
}
@keyframes fall_6 {
  0% {
  }
  100% {
    left: 1100px;
    top: 800px;
  }
}
@keyframes fall_7 {
  0% {
  }
  100% {
    left: 1700px;
    top: 500px;
  }
}

@keyframes fall_8 {
  0% {
  }
  100% {
    left: 150px;
    top: 800px;
  }
}

@keyframes fall_9 {
  0% {
  }
  100% {
    left: 1100px;
    top: 800px;
  }
}
@keyframes fall_10 {
  0% {
  }
  100% {
    left: 1100px;
    top: 800px;
  }
}
@keyframes fall_11 {
  0% {
  }
  100% {
    left: 1488px;
    top: 800px;
  }
}
@keyframes fall_12 {
  0% {
  }
  100% {
    left: 400px;
    top: 1120px;
  }
}
@keyframes fall_13 {
  0% {
  }
  100% {
    left: 1600px;
    top: 120px;
  }
}
