.navbar {
  padding: 10px 0px;
  width: 100%;
  height: fit-content;
  top: 0;
  border-bottom: 5px solid black;
  margin-bottom: 0px;
}

.nav-link {
  font-weight: 800;
  font-size: 18px;
  margin: 4px 10px;
  color: white;
}
.fa-bars {
  color: white;
}
#navbarNav span {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: grey;
  padding: 5px;
  border-radius: 10px;
}

.image-icon {
  width: 28px;
}
@media screen and (max-width: 992px) {
  .nav-link {
    text-align: center;
  }
  .navbar {
    position: relative;
    z-index: 1;
  }
}
